/* Form to upload a file and process it */

import {
    Button,
    Heading,
    Loader,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    Text
} from "@aws-amplify/ui-react";
import {StorageManager} from "@aws-amplify/ui-react-storage";
import React, {useEffect, useState} from "react";
import {API, Storage} from "aws-amplify";


function PreviousPdfSelector({previousPdfs, setPdfEntry}) {

    const [selectedPdf, setSelectedPdf] = useState(null);

    function extractFilename(s3Url) {
        const parts = s3Url.split('/');
        return parts[parts.length - 1];
    }

    function formatDateTime(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('de-DE', {
            'day': '2-digit',
            'month': '2-digit',
            'year': 'numeric',
            'hour': '2-digit',
            'minute': '2-digit'
        }) + ' Uhr';
    }

    function selectPdf(pdf) {
        setSelectedPdf(pdf);
        setPdfEntry(pdf);
        setSelectedPdf(null);
    }

    return <>
        <Table highlightOnHover="true" style={{width: '100%'}}>
            <TableHead backgroundColor="#eee">
                <TableRow>
                    <TableCell as="th">Dateiname</TableCell>
                    <TableCell as="th">Uploadzeit</TableCell>
                    <TableCell as="th">Seiten</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {previousPdfs.map((pdf, index) =>
                    <TableRow key={'page_' + index} onClick={() => selectPdf(pdf)} style={{cursor: 'pointer'}}>
                        <TableCell>
                            <Text>{extractFilename(pdf.pdf_s3_url)}</Text>
                            {selectedPdf === pdf ? <Loader variation="linear" /> : null}
                        </TableCell>
                        <TableCell>{formatDateTime(pdf.created_at)}</TableCell>
                        <TableCell>
                            {pdf.page_count}
                            {pdf.processed_pages < pdf.page_count ? ` (${pdf.page_count - pdf.processed_pages} Seiten fehlen)` : null}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </>
}


export default function PdfSelector({user, onSelected}) {
    const apiName = 'scanSplitterPdfRest';
    const [pdfFile, setPdfFile] = useState('');
    const [isProcessingPdf, setProcessingPdf] = useState(false);
    const [numPagesToProcess, setNumPagesToProcess] = useState(0);
    const [numPagesProcessed, setNumPagesProcessed] = useState(0);
    const [previousPdfs, setPreviousPdfs] = useState([]);
    const [isLoading, setLoading] = useState(true);

    async function analyzePdf() {
        const s3Url = await Storage.get(pdfFile, {level: 'private'} );
        setProcessingPdf(true);
        const response = await API.post(apiName, '/pdfs', {'body': {
            's3_url': s3Url,
            'user_id': user.username}
        });
        const final_doc = await waitForPdfBeProcessed(response.pdf_id);
        onSelected(final_doc);
        setProcessingPdf(false);
    }

    async function waitForPdfBeProcessed(pdfId) {
        let finished = false;
        while (!finished) {
            const response = await API.get(apiName, `/pdfs/${user.username}/${pdfId}`, {});
            if (response.page_count === response.processed_pages) {
                finished = true;
                return response;
            }
            if (response.processing_error !== '') {
                const message = 'Fehler beim Verarbeiten der Datei: ' + response.processing_error;
                alert(message);
                finished = true;
                setProcessingPdf(false);
                throw new Error(message)
            }
            setNumPagesToProcess(response.page_count);
            setNumPagesProcessed(response.processed_pages);
            await new Promise(r => setTimeout(r, 2000));
        }
    }

    function selectPdf(pdfEntry) {
        const fetchData = async () => {
            const response = await API.get(apiName, `/pdfs/${user.username}/${pdfEntry.pdf_id}`, {})
            for (const page of response.pages) {
                if (page.invoice_name === null) {
                    page.invoice_name = '';
                }
            }
            onSelected(response);
        }

        fetchData().catch(console.error);
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await API.get(apiName, `/pdfs/${user.username}`, {})
            setLoading(false);
            setPreviousPdfs(response);
        }

        fetchData().catch(console.error);
    }, [user.username]);

    return <>
            <Heading level={3}>Deine bisherigen Rechnungen</Heading>
            {isLoading ? <Loader  /> : <>
                {previousPdfs.length > 0 ?
                    <PreviousPdfSelector previousPdfs={previousPdfs} setPdfEntry={selectPdf} /> :
                    <Text>Du hast noch keine Rechnungen hochgeladen</Text>
                }
            </>}

            <Heading level={3}>Neue Rechnung hochladen</Heading>
            <StorageManager
                acceptedFileTypes={['application/pdf']}
                accessLevel="private"
                maxFileCount={1}
                displayText={{
                    dropFilesText: 'Datei hier hinziehen',
                    browseFilesText: 'Datei auswählen',
                    getFilesUploadedText(count) {
                        return `${count} PDF hochgeladen`;
                    }
                }}
                onUploadSuccess={({ key }) => {setPdfFile(key)}}
                onFileRemove={({ key }) => {setPdfFile('')}}
                isResumable
            />
            {pdfFile ? <Button
                variation="primary"
                isLoading={isProcessingPdf}
                loadingText={"Datei wird analysiert..." + (numPagesToProcess > 0 ? ` (${numPagesProcessed} von ${numPagesToProcess} Seiten)` : '')}
                onClick={analyzePdf}>Datei Analysieren</Button> : null}
        </>
}
