/* src/App.js */
import React from 'react'
import {Amplify, API} from 'aws-amplify'

// amplify UI components
import {Flex, Heading, withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {useImmerReducer} from 'use-immer';

// amplify configuration
import awsExports from "./aws-exports";

// own components
import PdfSelector from "./PdfSelector";
import PdfViewer from "./PdfViewer";
import Footer from "./Footer";
import './App.css';

Amplify.configure(awsExports);

function pdfActionReducer(draft, action) {
    switch (action.type) {
        case 'load':
            draft = action.pdfEntry;
            return draft;
        case 'reset':
            draft = null;
            return draft;
        case 'belongsToPreviousChanged':
            draft.pages[action.index].belongs_to_previous_page = action.value;
            return draft;
        case 'nameChanged':
            draft.pages[action.index].invoice_name = action.value;
            return draft;
        default:
            throw new Error('Unknown action type: ' + action.type);
    }
}

const App = ({signOut, user}) => {
    const [pdfEntry, dispatch] = useImmerReducer(pdfActionReducer, null);
    const [isSaving, setIsSaving] = React.useState(false);
    const [isSplitting, setIsSplitting] = React.useState(false);
    const apiName = 'scanSplitterPdfRest';

    function savePdfEntry() {
        setIsSaving(true);
        API.put(apiName, `/pdfs/${user.username}/${pdfEntry.pdf_id}`, {
                'body': {
                    'pages': pdfEntry.pages.map(page => {
                        return {
                            belongs_to_previous_page: page.belongs_to_previous_page,
                            invoice_name: page.invoice_name
                        }
                    })
                }
            }
        ).catch(console.error).finally(() => setIsSaving(false));
    }

    function splitPdfEntry() {
        setIsSplitting(true);
        API.put(apiName, `/pdfs/${user.username}/${pdfEntry.pdf_id}`, {
                'body': {
                    'pages': pdfEntry.pages.map(page => {
                        return {
                            belongs_to_previous_page: page.belongs_to_previous_page,
                            invoice_name: page.invoice_name
                        }
                    })
                }
            }
        ).catch((error) => {
            console.error(error);
            alert('Fehler beim Aufteilen der PDF-Datei: ' + error.message);
            setIsSplitting(false);
        }).then(() => {
            API.get(apiName, `/pdfs/${user.username}/${pdfEntry.pdf_id}/get_zip_download_url`, {})
                .catch((error) => {
                        console.error(error);
                        alert('Fehler beim Erstellen des Download-Links: ' + error.message);
                    })
                .then((response) => {
                    const a = document.createElement('a');
                    a.href = response;
                    a.download = response.split('/').pop();
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }).finally(() => setIsSplitting(false));
        });
    }

    return (
        <Flex direction="column" className={pdfEntry === null ? "root-container" : "root-container large"}>
            <Heading level={1}>PDF Invoice Splitter</Heading>
            {pdfEntry === null ?
                <PdfSelector
                    user={user}
                    onSelected={(entry) => {
                        dispatch({type: 'load', pdfEntry: entry})
                    }}
                />
                :
                <PdfViewer
                    pdfEntry={pdfEntry}
                    isSaving={isSaving}
                    isSplitting={isSplitting}
                    onCancel={() => {
                        dispatch({type: 'reset'})
                    }}
                    onBelongsToPreviousChanged={(index, value) => dispatch({
                        type: 'belongsToPreviousChanged',
                        index: index,
                        value: value
                    })}
                    onNameChanged={(index, value) => dispatch({
                        type: 'nameChanged',
                        index: index,
                        value: value
                    })}
                    onSave={() => savePdfEntry()}
                    onSplit={() => splitPdfEntry()}
                />
            }
            <Footer signOut={signOut}/>
        </Flex>
    );
}

export default withAuthenticator(App, {hideSignUp: true});