import {Button, Divider} from "@aws-amplify/ui-react";
import React from "react";

export default function Footer({signOut}) {
    return (
        <div>
            <Divider style={styles}/>
            <Button onClick={signOut}>Abmelden</Button>
        </div>
    )
}

const styles = {
    margin: '20px 0px'
}