import {
    Button,
    CheckboxField,
    Flex,
    Input, Message,
    ScrollView,
    Text
} from "@aws-amplify/ui-react";
import {StorageImage} from "@aws-amplify/ui-react-storage";
import React, {useState} from "react";
import "./PdfViewer.css"


function PageThumbnail({index, page, type, onBelongsToPreviousChanged, onNameChanged, onSelected}) {
    return (
        <Flex key={'page_' + index} direction="column" gap="0" className={"thumbnail-box " + type}>
            <StorageImage
                imgKey={page.storage_key}
                accessLevel="private"
                alt={"Seite " + (index + 1)}
                className="thumbnail"
                onClick={() => onSelected()}
            />
            <Flex key={page} direction="row" gap="0">
                {index > 0 ? <CheckboxField
                    label={page.belongs_to_previous_page ? "Gehört zur vorigen Seite" : ""}
                    checked={page.belongs_to_previous_page}
                    onChange={(e) => onBelongsToPreviousChanged(e.target.checked)}
                    marginRight={page.belongs_to_previous_page ? "0" : "0.5rem"}
                /> : null}

                {!page.belongs_to_previous_page || index === 0? <Input
                    placeholder="Rechnungsname"
                    className="input"
                    value={page.invoice_name}
                    onChange={(e) => onNameChanged(e.target.value)}
                /> : null}
            </Flex>
        </Flex>
    )
}


export default function PdfViewer({pdfEntry, isSaving, isSplitting, onCancel, onBelongsToPreviousChanged, onNameChanged, onSave, onSplit}) {
    const fileName = pdfEntry.pdf_s3_url.split('/').pop()
    const pageCount = pdfEntry.page_count
    const [currentPage, setCurrentPage] = useState(0)

    function getPageType(index) {
        if (!pdfEntry.pages[index].belongs_to_previous_page) {
            if (index === pageCount - 1) {
                return 'single'
            }

            if (pdfEntry.pages[index + 1].belongs_to_previous_page) {
                return 'top'
            }

            return 'single'
        }

        if (index === pageCount - 1 || !pdfEntry.pages[index + 1].belongs_to_previous_page) {
            return 'bottom'
        }

        return 'middle'
    }

    const pages = pdfEntry.pages.map((page, index) => (
        <PageThumbnail
            index={index}
            page={page}
            onBelongsToPreviousChanged={(value) => onBelongsToPreviousChanged(index, value)}
            onNameChanged={(value) => onNameChanged(index, value)}
            onSelected={() => {setCurrentPage(index)}}
            type={getPageType(index)}
        />
    ))

    return <>
            <Message variation="filled" colorTheme="info" heading="PDF Bearbeitung" margin="20px 0">
                Jede Seite kann als einzelnes PDF oder zusammen mit der oden den vorigen Seite(n) abgespeichert werden.
                Dazu gibt es entweder ein Namensfeld für die Rechnung oder eine Checkbox, die anzeigt,
                dass es zur vorigen Seite gehört."
            </Message>
            <Text fontWeight="bold">PDF Dokument: {fileName} ({pdfEntry.page_count} Seiten)</Text>

            <Flex direction="row" gap="1rem" wrap="nowrap" className="main">
                <ScrollView className="preview-scrollview">
                    <StorageImage
                        imgKey={pdfEntry.pages[currentPage].storage_key}
                        accessLevel="private"
                        alt="Vorschau"
                        className="preview" />
                </ScrollView>

                <ScrollView className="thumbnail-scrollview">
                    <Flex direction="column" gap="0" paddingRight="1rem">
                        {pages}
                    </Flex>
                </ScrollView>
            </Flex>

            <Flex direction="row" gap="1rem" wrap="nowrap" className="button-box">
                <Button onClick={onSplit} isLoading={isSplitting} variation="primary" >PDF Aufteilen</Button>
                <Button onClick={onSave} isLoading={isSaving}>Speichern</Button>
                <Button onClick={onCancel} variation="primary" colorTheme="error">Abbrechen</Button>
            </Flex>
        </>
}
